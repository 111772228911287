<template>
  <div>

    <b-card no-body>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <b-card-title class="mb-1">
            Faaliyet Detayları
          </b-card-title>
          <b-card-sub-title />
        </div>
        <!--/ title and subtitle -->

        <!-- badge -->
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
          <h5 class="font-weight-bolder mb-0 mr-1" />

        </div>
      <!--/ badge -->
      </b-card-header>

      <b-card-body>
        <!-- search input -->
        <div
          v-if="true==false"
          class="custom-search d-flex justify-content-end"
        >
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Şehir/Ülke Arayın</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Bölge Adı Giriniz"
                type="text"
                class="d-inline-block"
                @input="handleSearch"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :is-loading.sync="isLoading"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          @on-sort-change="onSortChange"
        > <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'total_price_currency_converted'">
              <span>{{ $staticParams.formatCurrency(props.row.total_price_currency_converted) }}</span>
            </span>
            <span v-else-if="props.column.field == 'count'">
              <span>{{ $staticParams.formatCurrency(props.row.count,0) }}</span>
            </span>
            <span
              v-else
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ totalRows }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="totalRows"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BPagination, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'

export default {
  components: {
    BCardHeader,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    VueGoodTable,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    userid: {
      type: Number,
      default: 0,
    },
    startdate: {
      type: String,
      required: true,
    },
    enddate: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

      pageLength: 10,
      dir: false,
      isLoading: false,
      sort: 'desc',
      totalRows: 0,
      order_column: 'start_date',
      pages: ['10', '30', '50'],
      page: 1,
      columns: [
        {
          label: 'Personel',
          field: 'user_name',
        },
        {
          label: 'Başlık',
          field: 'title',
        },
        {
          label: 'Faaliyet Türü',
          field: 'activity_type_name',
        },
        {
          label: 'Faaliyet Zamanı',
          field: 'start_date',
        },
        {
          label: 'Süre',
          field: 'time',
        },
        {
          label: 'Müşteri',
          field: 'customer_name',
        },
        {
          label: 'Lokasyon',
          field: 'location',
        },
        {
          label: 'Açıklama',
          field: 'description',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {

  },
  watch: {
    userid() {
      this.fetchData()
    },
    startdate() {
      this.fetchData()
    },
    enddate() {
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      store
        .dispatch('app-reports/fetchActivityDetails', {
          user_id: this.userid,
          start_date: this.startdate,
          end_date: this.enddate,
          ordering: this.sort,
          data_count: this.pageLength,
          search_term: this.searchTerm,
          order_column: this.order_column,
          page: this.page,
        })
        .then(response => {
          const { data } = response.data.data
          const { meta } = response.data
          this.totalRows = meta.total
          data.forEach(val => {
            if (val.city_country === '') {
              // eslint-disable-next-line no-param-reassign
              val.city_country = 'Diğer'
            }
          })
          this.rows = data
        })
        .catch(() => {
        })
    },
    // eslint-disable-next-line no-unused-vars
    handleSearch(searching) {
      this.fetchData()
    },
    handleChangePage(page) {
      this.page = page
      this.fetchData()
    },
    // eslint-disable-next-line no-unused-vars
    handlePageChange(active) {
      this.fetchData()
    },
    onSortChange(params) {
      this.order_column = params[0].field
      this.sort = params[0].type
      this.fetchData()
    },
  },

}
</script>
<style  lang="scss">
table.vgt-table {
    font-size: 13px !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>

<template>
  <div>
    <b-row>
      <b-col
        md="12"
        sm="12"
      >
        <b-card>

          <b-form

            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col md="4"> <b-form-group
                :label="$t('Başlangıç Tarihi')"
                label-for="name"
              >
                <b-form-datepicker
                  id="start_date"
                  v-model="filterData.start_date"
                  class="mb-1"
                />
              </b-form-group></b-col>
              <b-col md="4"> <b-form-group
                :label="$t('Bitiş Tarihi')"
                label-for="name"
              >
                <b-form-datepicker
                  id="end_date"
                  v-model="filterData.end_date"
                  class="mb-1"
                />
              </b-form-group></b-col>

            </b-row>

          </b-form>

        </b-card>

      </b-col>
      <b-col

        md="12"
      >
        <activity-list
          :startdate="filterData.start_date"
          :enddate="filterData.end_date"
        />
      </b-col>
    </b-row>
    <div />
  </div></template>

<script>
import {
  BCard, BRow, BCol, BFormDatepicker, BForm, BFormGroup,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import activityList from './ActivityList.vue'
import reportsStoreModule from '../reportsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    activityList,
    BFormDatepicker,
    BForm,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filterData: {
        start_date: null,
        end_date: null,
        user_id: 0,
      },
      reportData: null,

    }
  },
  computed: {

  },
  created() {

  },

  methods: {

  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, reportsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // eslint-disable-next-line no-unused-vars
    const exData = ref(null)

    return {

      // Filter
      avatarText,

      // UI
      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<template>
  <div>
    <div class="d-flex mt-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="fetchData"
      >
        Filtrele
      </b-button>
    </div>
    <hr>
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                Personel Faaliyet Listesi
              </b-card-title>
              <b-card-sub-title>Satır Üzerine Çift Tıklayarak Detayları Görebilirsiniz</b-card-sub-title>
            </div>
            <!--/ title and subtitle -->

            <!-- badge -->
            <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1" />
            <!--/ badge -->
          </b-card-header>

          <b-card-body>
            <b-table
              small
              :fields="debtTable.fields"
              :items="data"
              selectable
              responsive="sm"
              select-mode="single"
              @row-selected="dataSelected"
            >
              <template #cell(total_due)="data">
                <strong>  {{ $staticParams.formatCurrency(data.item.total_due) }}</strong>
              </template>
              <!-- A custom formatted column -->
              <template #cell(time)="data">
                {{ data.value+' DK.' }}
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="12">
        <activity-details
          v-if="seletedData"
          :userid="seletedData.user_id"
          :startdate="startdate"
          :enddate="enddate"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BTable, BRow, BCol, VBModal, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import activityDetails from './ActivityDetails.vue'

export default {
  components: {
    BCardHeader,
    BCard,
    BButton,
    activityDetails,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    startdate: {
      type: String,
      required: true,
    },
    enddate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      debtTable: {
        fields: [
          { key: 'user_name', label: 'Kullanıcı' },
          // A regular column
          { key: 'total_activity', label: 'Falliyet Sayısı' },
        ],
      },
      data: null,
      seletedData: null,
    }
  },
  watch: {
    startdate() {
      this.fetchData()
    },
    enddate() {
      this.fetchData()
    },
  },
  methods: {
    fetchData() {
      store
        .dispatch('app-reports/fetchActivityList', {
          user_id: this.userid,
          start_date: this.startdate,
          end_date: this.enddate,
          ordering: this.sort,
          data_count: this.pageLength,
          search_term: this.searchTerm,
          order_column: this.order_column,
          page: this.page,
        })
        .then(response => {
          const { data } = response.data.data
          const { meta } = response.data
          this.totalRows = meta.total
          data.forEach(val => {
            if (val.city_country === '') {
              // eslint-disable-next-line no-param-reassign
              val.city_country = 'Diğer'
            }
          })
          this.data = data
        })
        .catch(() => {
        })
    },
    dataSelected(item) {
      // eslint-disable-next-line prefer-destructuring
      this.seletedData = item[0]
      // eslint-disable-next-line prefer-destructuring
    },
  },
}
</script>
